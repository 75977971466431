import axios from '@axios'
import store from '@/store'

export default {
  async postProcesoData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getProcesos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postJerarquiaData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getJerarquias`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postFrecuenciaData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getFrecuencias`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postHoraData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getHoras`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postMinutoData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getMinutos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postProcesoInsert(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertProcesos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  },
  async postProcesoUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/updateProcesos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: data.usuario,
        jerarquia: data.jerarquia,
        nombre: data.nombre,
        frecuencia: data.frecuencia,
        hora: data.hora,
        minuto: data.minuto,
        tipoEnvio: data.tipo,
        titulo: data.titulo,
        mensaje: data.mensaje,
        sonido: data.sonido,
        descripcion: data.descripcion,
        estado: data.estado,
        idValue: data.id,
        ocurrencia: data.ocurrencia,
      },
    })
    return response
  },
  async postProcesoDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteProcesos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
}
