import axios from '@axios'
import store from '@/store'

export default {
  async postCampanaEmpresaUsuarioData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getCampaniasByUser`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postCampanaData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getCampanias`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
  async postCampanaEmpresaData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getCampaniasByCompany`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
  async postCampanaInsert(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertCampanias`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: data.nombre,
        areaId: data.area,
        reinicio: data.reinicio,
        estado: data.estado,
        autenticacion: data.autenticacion,
        avanceComisiones: data.avanceComisiones,
        chatSoporte: data.chatSoporte,
        etiquetaAccionReemplazo: data.totalProducto,
        mostrar: data.mostrar,
        numeroSoporte: data.numeroSoporte,
        objetivoDiario: data.objetivoDiario,
        trivia: data.trivia,
        urlChatSoporte: data.urlChat,
        urlReporte: data.urlReporteSupervisor,
        urlReporteAdmin: data.urlReporteAdministrador,
        formCantadas: data.formularioCantadas,
      },
    })
    return response
  },
  async postCampanaUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/updateCampanias`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: data.nombre,
        areaId: data.area,
        reinicio: data.reinicio,
        estado: data.estado,
        idValue: data.id,
        autenticacion: data.autenticacion,
        avanceComisiones: data.avanceComisiones,
        chatSoporte: data.chatSoporte,
        etiquetaAccionReemplazo: data.totalProducto,
        mostrar: data.mostrar,
        numeroSoporte: data.numeroSoporte,
        objetivoDiario: data.objetivoDiario,
        trivia: data.trivia,
        urlChatSoporte: data.urlChat,
        urlReporte: data.urlReporteSupervisor,
        urlReporteAdmin: data.urlReporteAdministrador,
        vigenciaMonedas: data.vuinaCoins.vigencia,
        formCantadas: data.formularioCantadas,
      },
    })
    return response
  },
  async postCampanaDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteCampanias`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
  async postCampanaInsertProducto(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertProductosCampania`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: data.id,
        producto: data.producto,
      },
    })
    return response
  },
  async postCampanaDeleteProducto(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteProductosCampania`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: data.id,
      },
    })
    return response
  },
  async postCampanaUpdateProducto(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/updateProductosCampania`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: data.id,
        producto: data.producto,
      },
    })
    return response
  },
  async postCampanaProceder(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertProcesoBorradoMonedas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: data.campana,
        fechaProgramada: data.fecha,
        usuarioRegistro: data.usuario,
      },
    })
    return response
  },
  async postCampanaCancelarBorrado(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteProcesoBorradoMonedas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: data.campana,
        usuarioRegistro: data.usuario,
      },
    })
    return response
  },
  async postCampanaInsertarPopup(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertPoppupInicio`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    })
    return response
  },
  async postCampanaActualizarEstadoPopup(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/setEstadoPoppup`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        campana: data.campana,
        estado: data.estado,
      },
    })
    return response
  },
}
