import axios from '@axios'
import store from '@/store'

export default {
  async postGrupoUsuarioData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getGroupsByUser`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
}
