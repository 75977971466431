<template>
  <div>
    <div class="d-block d-md-none mb-2">
      <b-button
        v-show="$can('notificacionCrear', 'notificacionCrear')"
        variant="primary"
        block
        @click="modalAgendamiento(0)"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Crear notificación</span>
      </b-button>
    </div>
    <div class="d-none d-md-flex align-items-center justify-content-end mb-2">
      <b-button
        v-show="$can('notificacionCrear', 'notificacionCrear')"
        variant="primary"
        @click="modalAgendamiento(0)"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Crear notificación</span>
      </b-button>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card title="Notificaciones creados">
          <div class="d-flex justify-content-between flex-wrap my-2">
            <!-- filter -->
            <b-form-group
              label="Buscador"
              label-cols-sm="3"
              label-align-sm="left"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filterAgendamiento"
                  placeholder="Buscador"
                  type="search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filterAgendamiento"
                    @click="filterAgendamiento = ''"
                  >
                    Limpiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <b-table
            responsive
            striped
            hover
            show-empty
            bordered
            sticky-header="550px"
            :small="true"
            :busy="cargandoAgendamiento"
            :items="itemsAgendamiento"
            :fields="fieldsAgendamiento"
            :filter="filterAgendamiento"
            :filter-included-fields="filterOnAgendamiento"
            empty-filtered-text="No se encontro ningun resultado"
            empty-text=" "
          >
            <template #empty>
              <h4 class="text-center my-2">
                No tiene información para mostrar
              </h4>
            </template>
            <template #emptyfiltered="scope">
              <h4 class="text-center my-2">
                {{ scope.emptyFilteredText }}
              </h4>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
                <strong class="ml-1">Cargando ...</strong>
              </div>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(imagen)="row">
              <b-img
                :src="row.item.imagen"
                rounded
                width="70"
                height="50"
              />
            </template>
            <template v-slot:cell(activo)="row">
              <span v-if="row.item.activo === '0'">Inactivo</span>
              <span v-else>Activo</span>
            </template>
            <template v-slot:cell(fecha)="row">
              {{ row.item.hora }} : {{ row.item.minuto }}
            </template>
            <template #cell(acciones)="row">
              <b-button
                v-show="$can('notificacionActualizar', 'notificacionActualizar')"
                variant="gradient-success"
                class="btn-icon mr-50"
                size="sm"
                @click="modalAgendamiento(row.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-show="$can('notificacionEliminar', 'notificacionEliminar')"
                variant="gradient-danger"
                class="btn-icon"
                size="sm"
                @click="eliminarAgendamiento(row.item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="popupAgregarAgendamiento"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="(form.id > 0) ? 'xl': 'lg'"
      :title="tituloAgendamiento"
      @hidde="limpiarMensajeTotal"
    >
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between">
            <p class="text-right">
              <small class="text-muted">Los campos marcados con * son obligatorios</small>
            </p>
            <div class="d-flex align-items-center justify-content-end mb-2">
              <b-button
                variant="primary"
                class="ml-1"
                @click="registrarMensaje"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50 text-white"
                />
                <span class="text-white">Grabar</span>
              </b-button>
              <b-button
                variant="danger"
                class="ml-1"
                @click="limpiarMensaje"
              >
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                Limpiar
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="form.id > 0"
          md="4"
        >
          <b-img
            :src="form.urlImagen"
            rounded
            fluid-grow
          />
        </b-col>
        <b-col :md="(form.id > 0) ? '8': '12'">
          <validation-observer ref="filterAgendamiento">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="jerarquia"
                  label="Jerarquia *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="jerarquia"
                    rules="required"
                  >
                    <b-form-select
                      id="jerarquia"
                      ref="jerarquia"
                      v-model="form.jerarquia"
                      :options="optionsJerarquia"
                      :state="errors.length > 0 ? false : null"
                      @input="form.nombre=null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="frecuencia"
                  label="Frecuencia *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="frecuencia"
                    rules="required"
                  >
                    <b-form-select
                      id="frecuencia"
                      ref="frecuencia"
                      v-model="form.frecuencia"
                      :options="optionsFrecuencia"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label-for="nombre"
                  label="Nombre *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-select
                      v-if="form.jerarquia=='1' || form.jerarquia== null"
                      id="nombre"
                      ref="nombre"
                      v-model="form.nombre"
                      :options="optionsGlobal"
                      disabled
                    />
                    <b-form-select
                      v-if="form.jerarquia=='2'"
                      id="nombre"
                      ref="nombre"
                      v-model="form.nombre"
                      :options="optionsGrupo"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-select
                      v-if="form.jerarquia=='3'"
                      id="nombre"
                      ref="nombre"
                      v-model="form.nombre"
                      :options="optionsEmpresa"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-select
                      v-if="form.jerarquia=='4'"
                      id="nombre"
                      ref="nombre"
                      v-model="form.nombre"
                      :options="optionsArea"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-select
                      v-if="form.jerarquia=='5'"
                      id="nombre"
                      ref="nombre"
                      v-model="form.nombre"
                      :options="optionsCampana"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label-for="hora"
                  label="Hora *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="hora"
                    rules="required"
                  >
                    <b-form-select
                      id="hora"
                      ref="hora"
                      v-model="form.hora"
                      :options="optionsHora"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label-for="minuto"
                  label="Minuto *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="minuto"
                    rules="required"
                  >
                    <b-form-select
                      id="minuto"
                      ref="minuto"
                      v-model="form.minuto"
                      :options="optionsMinuto"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="titulo"
                  label="Titulo *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="titulo"
                    rules="required"
                  >
                    <b-form-input
                      id="titulo"
                      ref="titulo"
                      v-model="form.titulo"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar titulo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="mensaje"
                  label="Mensaje *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="mensaje"
                    rules="required"
                  >
                    <b-form-textarea
                      id="mensaje"
                      ref="mensaje"
                      v-model="form.mensaje"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar mensaje"
                      rows="3"
                      max-rows="6"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="form.id == 0">
              <b-col>
                <b-form-group
                  label-for="imagen"
                  label="Imagen *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="imagen"
                    rules="required"
                  >
                    <b-form-file
                      id="imagen"
                      ref="imagen"
                      v-model="form.imagen"
                      placeholder="Seleccionar imagen..."
                      drop-placeholder="Eliminar imagen..."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  label-for="tipo"
                  label="Tipo proceso *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tipo"
                    rules="required"
                  >
                    <b-form-select
                      id="tipo"
                      ref="tipo"
                      v-model="form.tipo"
                      :options="optionsTipoProceso"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-for="ocurrencia"
                  label="Ocurrencia *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="ocurrencia"
                    rules="required"
                  >
                    <b-form-select
                      id="ocurrencia"
                      ref="ocurrencia"
                      v-model="form.ocurrencia"
                      :options="optionsOcurrencia"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-for="estado"
                  label="Estado *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="estado"
                      ref="estado"
                      v-model="form.estado"
                      :options="optionsEstado"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BFormSelect,
  BModal,
  BFormGroup,
  BFormInput,
  BFormFile,
  BImg,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import tipoService from '@/service/tipoProcesoService'
// eslint-disable-next-line import/no-cycle
import empresaService from '@/service/empresaService'
// eslint-disable-next-line import/no-cycle
import agendamientoService from '@/service/agendamientoService'
// eslint-disable-next-line import/no-cycle
import grupoService from '@/service/grupoService'
// eslint-disable-next-line import/no-cycle
import areaService from '@/service/areaService'
// eslint-disable-next-line import/no-cycle
import campanaService from '@/service/campanaService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BImg,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      required,
      cargandoAgendamiento: false,
      filterAgendamiento: null,
      filterOnAgendamiento: [],
      fieldsAgendamiento: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Frecuencia',
          key: 'frecuencia_descripcion',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Hora',
          key: 'fecha',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Jerarquia',
          key: 'jerarquia_descripcion',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nombre',
          key: 'nombre_descripcion',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Titulo',
          key: 'titulo',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Imagen',
          key: 'imagen',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Estado',
          key: 'activo',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsAgendamiento: [],
      optionsGlobal: [
        { value: null, text: 'Seleccionar' },
      ],
      optionsTipoProceso: [
        { value: null, text: 'Seleccionar tipo' },
      ],
      optionsJerarquia: [
        { value: null, text: 'Seleccionar jerarquia' },
      ],
      optionsFrecuencia: [
        { value: null, text: 'Seleccionar frecuencia' },
      ],
      optionsHora: [],
      optionsMinuto: [],
      optionsGrupo: [{ value: null, text: 'Seleccionar grupo' }],
      optionsArea: [{ value: null, text: 'Seleccionar area' }],
      optionsEmpresa: [{ value: null, text: 'Seleccionar empresa' }],
      optionsCampana: [{ value: null, text: 'Seleccionar campaña' }],
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: '1', text: 'Activo' },
        { value: '0', text: 'Inactivo' },
      ],
      optionsOcurrencia: [
        { value: null, text: 'Seleccionar ocurrencia' },
        { value: '1', text: 'Recurrente' },
        { value: '0', text: 'Unica vez' },
      ],
      popupAgregarAgendamiento: false,
      tituloAgendamiento: '',
      form: {
        id: 0,
        usuario: 0,
        jerarquia: null,
        nombre: null,
        frecuencia: null,
        hora: '00',
        minuto: '00',
        titulo: '',
        mensaje: '',
        imagen: null,
        urlImagen: '',
        sonido: null,
        descripcion: '',
        tipo: null,
        ocurrencia: null,
        estado: '1',
      },
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      await this.obtenerTipoProceso()
      await this.obtenerFrecuencia()
      await this.obtenerJerarquia()
      await this.obtenerHora()
      await this.obtenerMinuto()
      await this.obtenerGrupo()
      await this.obtenerEmpresa()
      await this.obtenerArea()
      await this.obtenerCampana()
      await this.obtenerProceso()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async obtenerProceso() {
      this.itemsAgendamiento = []
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseArea = await agendamientoService.postProcesoData(
        this.token,
        this.userData.id,
      )
      if (responseArea.data.data.length > 0) {
        this.itemsAgendamiento = responseArea.data.data
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerTipoProceso() {
      this.optionsTipoProceso = [{ value: null, text: 'Seleccionar tipo' }]
      const responseTipo = await tipoService.postTipoProcesoData(
        this.token,
      )
      if (responseTipo.data.data.length > 0) {
        responseTipo.data.data.forEach(element => this.optionsTipoProceso.push({
          value: element.id,
          text: element.descripcion,
        }))
      }
    },
    async obtenerArea() {
      this.optionsArea = [{ value: null, text: 'Seleccionar area' }]
      const responseArea = await areaService.postAreaUsuarioData(
        this.token,
        this.userData.id,
      )
      if (responseArea.data.data.length > 0) {
        responseArea.data.data.forEach(element => this.optionsArea.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerGrupo() {
      this.optionsGrupo = [{ value: null, text: 'Seleccionar grupo' }]
      const responseGrupo = await grupoService.postGrupoUsuarioData(this.token, this.userData.id)
      if (responseGrupo.data.data.length > 0) {
        responseGrupo.data.data.forEach(element => this.optionsGrupo.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerEmpresa() {
      this.optionsEmpresa = [{ value: null, text: 'Seleccionar empresa' }]
      const responseEmpresa = await empresaService.postEmpresaUsuarioData(this.token, this.userData.id)
      if (responseEmpresa.data.data.length > 0) {
        responseEmpresa.data.data.forEach(element => this.optionsEmpresa.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerCampana() {
      this.optionsCampana = [{ value: null, text: 'Seleccionar campaña' }]
      const responseCampana = await campanaService.postCampanaEmpresaUsuarioData(
        this.token,
        this.userData.id,
      )
      if (responseCampana.data.data.length > 0) {
        responseCampana.data.data.forEach(element => this.optionsCampana.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerJerarquia() {
      this.optionsJerarquia = [{ value: null, text: 'Seleccionar jerarquia' }]
      const responseJerarquia = await agendamientoService.postJerarquiaData(
        this.token,
      )
      responseJerarquia.data.data.forEach(element => this.optionsJerarquia.push({
        value: element.id,
        text: element.descripcion,
      }))
    },
    async obtenerFrecuencia() {
      this.optionsFrecuencia = [{ value: null, text: 'Seleccionar frecuencia' }]
      const responseFrecuencia = await agendamientoService.postFrecuenciaData(
        this.token,
      )
      responseFrecuencia.data.data.forEach(element => this.optionsFrecuencia.push({
        value: element.id,
        text: element.descripcion,
      }))
    },
    async obtenerHora() {
      this.optionsHora = []
      const responseHora = await agendamientoService.postHoraData(this.token)
      responseHora.data.data.forEach(element => this.optionsHora.push({
        value: element.descripcion,
        text: element.descripcion,
      }))
    },
    async obtenerMinuto() {
      this.optionsMinuto = []
      const responseMinuto = await agendamientoService.postMinutoData(
        this.token,
      )
      responseMinuto.data.data.forEach(element => this.optionsMinuto.push({
        value: element.descripcion,
        text: element.descripcion,
      }))
    },
    async modalAgendamiento(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloAgendamiento = 'Agregar notificación'
        this.form = {
          id: 0,
          empresa: null,
          jerarquia: null,
          nombre: null,
          frecuencia: null,
          hora: '00',
          minuto: '00',
          mensaje: '',
          imagen: null,
          descripcion: '',
          estado: '1',
          ocurrencia: null,
          tipo: null,
        }
      } else {
        this.tituloAgendamiento = 'Modificar notificación'
        const data = this.itemsAgendamiento.find(element => element.id === id)
        this.form = {
          id: data.id,
          empresa: this.idEmpresa,
          jerarquia: data.jerarquia,
          titulo: data.titulo,
          nombre: data.nombre,
          frecuencia: data.frecuencia,
          hora: data.hora,
          minuto: data.minuto,
          mensaje: data.mensaje,
          imagen: '',
          urlImagen: data.imagen,
          descripcion: data.descripcion,
          estado: data.activo,
          tipo: data.tipoEnvio,
          ocurrencia: data.ocurrencia,
        }
      }
      this.popupAgregarAgendamiento = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async registrarMensaje() {
      const success = await this.$refs.filterAgendamiento.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.form.id === 0) {
            const data = new FormData()
            data.append('idUsuario', this.userData.id)
            data.append('jerarquia', this.form.jerarquia)
            data.append('nombre', this.form.nombre)
            data.append('frecuencia', this.form.frecuencia)
            data.append('hora', this.form.hora)
            data.append('minuto', this.form.minuto)
            data.append('titulo', this.form.titulo)
            data.append('mensaje', this.form.mensaje)
            data.append('imagen', this.$refs.imagen.files[0])
            data.append('sonido', '')
            data.append('descripcion', '')
            data.append('tipoEnvio', this.form.tipo)
            data.append('estado', this.form.estado)
            data.append('ocurrencia', this.form.ocurrencia)
            await agendamientoService.postProcesoInsert(this.token, data)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Mensaje registrado con éxito.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          } else {
            this.form.usuario = this.userData.id
            this.form.imagen = null
            this.form.sonido = null
            this.form.descripcion = ''
            await agendamientoService.postProcesoUpdate(this.token, this.form)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Mensaje actualizado con éxito.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          }

          this.popupAgregarAgendamiento = false
          setTimeout(() => {
            this.obtenerProceso()
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async eliminarMensaje(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await agendamientoService.postProcesoDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Proceso eliminado con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerProceso()
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    limpiarMensaje() {
      this.form.usuario = 0
      this.form.jerarquia = null
      this.form.nombre = null
      this.form.frecuencia = null
      this.form.hora = '00'
      this.form.minuto = '00'
      this.form.titulo = ''
      this.form.mensaje = ''
      this.form.sonido = null
      this.form.descripcion = ''
      this.form.tipo = null
      this.form.estado = '1'
      this.form.ocurrencia = null
    },
    limpiarMensajeTotal() {
      this.form.id = 0
      this.form.imagen = null
      this.form.urlImagen = ''
      this.limpiarMensaje()
    },
  },
}
</script>

<style></style>
