import axios from '@axios'
import store from '@/store'

export default {
  async postAreaUsuarioData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getAreasByUser`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postAreaUsuarioNoAsignadosData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getUsuariosNoAsignados`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: id,
      },
    })
    return response
  },
  async postAreaUsuarioAgregarNoAsignadosData(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertUsuarioAdministrador`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: data.empresa,
        idUsuario: data.usuario,
      },
    })
    return response
  },
  async postAreaUsuarioEliminarAsignadosData(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteUsuarioAdministrador`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: data.empresa,
        idUsuario: data.usuario,
      },
    })
    return response
  },
  async postAreaUsuarioAsignadoData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getAdministradoresAsignados`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: id,
      },
    })
    return response
  },
  async postAreaData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getAreas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
  async postAreaInsert(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertAreas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: data.nombre,
        companyId: data.empresa,
        estado: data.estado,
      },
    })
    return response
  },
  async postAreaUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/updateAreas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: data.nombre,
        companyId: data.empresa,
        estado: data.estado,
        idValue: data.id,
      },
    })
    return response
  },
  async postAreaDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteAreas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idValue: id,
      },
    })
    return response
  },
}
